@font-face {
  font-family: "Agenda";
  src: url(../../public/fonts/agenda-bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: "Agenda";
  src: url(../../public/fonts/agenda-semibold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: "Agenda";
  src: url(../../public/fonts/agenda-light.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Agenda";
  src: url(../../public/fonts/agenda-boldcondensed.ttf);
  font-weight: 900;
}
*{
  font-family: Agenda;
}
h6{
  margin-bottom: 0;
}

.table{
  // border-color: #C6C6C6 !important;
}
.table > thead{
  font-size: .7em;
}
.table-striped > thead{
  border-width: 0;
}

.table-striped span{
  margin-top:-3px;
  border-color: #C6C6C6;
  vertical-align: middle;
  box-sizing: border-box; 
  display: table-row;
  overflow: hidden;
}

.table-striped > tbody th, .table-striped > tbody td:not(:last-child){
  border-right: 1px solid #C6C6C6;
}
.table-striped > thead th{
  padding-bottom: .1em;
  border-width: 0;
}
.table-striped > tbody{
  border: 1px solid #C6C6C6;
}
$primary: #77508D;
$secondary: #878787;
$border-color: #878787;
$table-striped-bg: #F4F4F4;
$small-font-size: .83em;
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  21: $spacer * .6,
  22: $spacer * .7,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: 8vw,
);
@import '~bootstrap/scss/bootstrap.scss';